<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1">
      <dark-Toggler />
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto d-none d-lg-flex">
      <search-bar />
    </b-navbar-nav>
  </div>
</template>

<script>
import {BLink, BNavbarNav, } from 'bootstrap-vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    DarkToggler,
    SearchBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>
