<template>
  <div class="navbar-header">
    <ul class="nav navbar-nav">
      <li>
        <!--
        <b-link class="navbar-brand" to="#" >
          <span class="brand-logo">
            <b-img :src="megalineLogo" alt="logo" v-if="users.logo == 'megaline'" />
            <b-img :src="phamacyLogo" alt="logo" v-else-if="users.logo == '약국'" />
            <b-img :src="madeLogo"    alt="made" v-else-if="users.logo == 'made'" />
            <b-img :src="honeyLogo"   alt="honey" v-else-if="users.logo == 'honey'" />
            <b-img :src="boltonLogo"  alt="bolton" v-else-if="users.logo == 'bolton'" />
            <b-img :src="eagleLogo"   alt="eagle" v-else-if="users.logo == 'eagle'" />
            <b-img :src="friendLogo"  alt="friend" v-else-if="users.logo == 'friend'" />
            <b-img :src="mynameLogo"  alt="myname" v-else-if="users.logo == 'myname'" />
            <b-img :src="thenewLogo"  alt="thenew" v-else-if="users.logo == 'thenew'" />
            <b-img :src="scLogo"      alt="sc" v-else-if="users.logo == 'sc'" />
            <b-img :src="kingdomLogo" alt="sc" v-else-if="users.logo == 'kingdom'" />
            <b-img :src="raceLogo"    alt="sc" v-else-if="users.logo == 'race'" />
            <b-img :src="wLogo"       alt="sc" v-else-if="users.logo == 'wsports'" />
            <b-img :src="demoLogo"    alt="sc" v-else/>
          </span>
          
          <h2 class="brand-text mb-0" v-if="!users.logo">
            미등록 사이트
          </h2>
        </b-link> 
          -->
        </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  data () {
    return {
      users : [],
    }
  },
  setup() {
    // App Name
    /*
    const { appName, appLogoImage, megalineLogo, phamacyLogo, madeLogo, honeyLogo, boltonLogo, eagleLogo, friendLogo, mynameLogo, thenewLogo, scLogo, kingdomLogo, raceLogo, demoLogo, wLogo } = $themeConfig.app
    return {
            appName, appLogoImage, megalineLogo, phamacyLogo, madeLogo, honeyLogo, boltonLogo, eagleLogo, friendLogo, mynameLogo, thenewLogo, scLogo, kingdomLogo, raceLogo, demoLogo, wLogo
    }
            */
  },
  created(){
  },
  mounted(){
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
      document.title = this.users.logo.toUpperCase();
    }
  },
}
</script>