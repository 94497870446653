<template>
  <li class="nav-item" style="color:#ffe588;">
    <!--
      🕒
      <span class="ml-h mr-1" style="font-family:Noto Sans KR,sans-serif !important;" @click="test()">{{ now }}</span>
      -->
  </li>
</template>

<script>
import { BFormInput, BLink, BImg, BAvatar, } from 'bootstrap-vue'

export default {
  data() {
    return {
      now: "",
    }
  },
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
  },
  mounted(){
    this.updateNow();
    setInterval(this.updateNow.bind(this) , 1000);
  },
  methods: {
    updateNow() {
      var today = new Date();

      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var hours = ('0' + today.getHours()).slice(-2); 
      var minutes = ('0' + today.getMinutes()).slice(-2);
      var seconds = ('0' + today.getSeconds()).slice(-2); 

      this.now = year + '-' + month  + '-' + day + ' ' + hours + ':' + minutes  + ':' + seconds;
    },
  },
}
</script>
